import React from "react"
import Nav from "../components/Nav"
import NewMap from "../components/NewMap"
import { graphql } from "gatsby"
import Seo from "../components/seo"

export const query = graphql`
  query placeQuery {
    places: allDatoCmsPlace {
      nodes {
        name
        email
        typeOfPlace
        showEmail
        who
        location {
          latitude
          longitude
        }
        description
      }
    }
  }
`

export default function Test({ data }) {
  return (
    <>
      <Seo title="Trip Tips" />
      <Nav />
      <NewMap data={data} />
    </>
  )
}
