import React from "react"
import europe from "./globe-icons/europe.svg"
import asia from "./globe-icons/asia.svg"
import africa from "./globe-icons/africa.svg"
import northAm from "./globe-icons/north-central-am.svg"
import southAm from "./globe-icons/south-america.svg"

export default function Sidebar({ sidebarShow, setSidebarShow, flyToRegion }) {
  return (
    <div className={`sidebar ${sidebarShow && "opened"}`}>
      <div
        className={`toggle-sidebar ${sidebarShow && "open"}`}
        onClick={() => setSidebarShow(!sidebarShow)}
      >
        {sidebarShow ? <p>select region</p> : <img src={northAm} />}
      </div>
      <ul className={`${sidebarShow && "showing"}`}>
        <li className="europe" data-title="Europe">
          <img
            src={europe}
            alt="europe"
            data-lat="41.382894"
            data-long="2.177432"
            onClick={e => flyToRegion(e)}
          />
        </li>
        <li className="asia" data-title="Asia">
          <img
            src={asia}
            alt="asia"
            data-lat="17.140790393"
            data-long="131.396484375"
            onClick={e => flyToRegion(e)}
          />
        </li>
        <li className="southAm" data-title="South America">
          <img
            src={southAm}
            alt="south america"
            data-lat="-11.867350911459294"
            data-long="-58.62304687499999"
            onClick={e => flyToRegion(e)}
          />
        </li>
        <li className="africa" data-title="Africa">
          <img
            src={africa}
            alt="africa"
            data-lat="19.248046875"
            data-long="7.885147"
            onClick={e => flyToRegion(e)}
          />
        </li>
        <li className="northCentralAm" data-title="North and Central America">
          <img
            src={northAm}
            alt="north and central america"
            data-lat="33.50475906922609"
            data-long="-92.8125"
            onClick={e => flyToRegion(e)}
          />
        </li>
      </ul>
    </div>
  )
}
