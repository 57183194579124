import React from "react"
import ex from "./noun-x.svg"

export default function PlacePost({ post, handlePostClose, opened = false }) {
  const { name, description, email, typeOfPlace, who, showEmail, location } =
    post
  return (
    <div className="map-post">
      <div className="close" onClick={() => handlePostClose()}>
        <img src={ex} alt="close" />
      </div>
      <div className="title-info">
        <h4>{name}</h4>
        <p>{typeOfPlace === "bar_cafe" ? "Bar/Cafe" : typeOfPlace}</p>
      </div>
      {who && (
        <h5>
          <span>{who}</span> says:
        </h5>
      )}
      <p className="content">{description}</p>
      <a
        className="pin-btn"
        target="_blank"
        href={`http://maps.google.com/maps?q=${location.latitude},${location.longitude}`}
      >
        📍 Pin it on Google Maps
      </a>
      {showEmail && (
        <a
          href={`mailto:${email}`}
          target="_blank"
          rel="noreferrer"
          className="email-btn"
        >
          ✉️ Email {who}
        </a>
      )}
    </div>
  )
}
