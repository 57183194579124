import React from "react"
import rest from "./key-images/restaurant-v2.svg"
import bar from "./key-images/beer-v2.svg"
import shop from "./key-images/shop-v2.svg"
import star from "./key-images/star.svg"
import view from "./key-images/viewpoint-v2.svg"
import museo from "./key-images/museum-v2.svg"
import "./index.scss"

export default function MapKey() {
  return (
    <div className="map-key">
      <p>KEY ➤</p>
      <ul>
        <li className="restaurant">
          <img src={rest} alt="restaurant" />
        </li>
        <li className="bar">
          <img src={bar} alt="bar or cafe" />
        </li>
        <li className="shop">
          <img src={shop} alt="shop" />
        </li>
        <li className="museum">
          <img src={museo} alt="museum" />
        </li>
        <li className="view">
          <img src={view} alt="view" />
        </li>
        <li className="other">
          <img src={star} alt="other" />
        </li>
      </ul>
    </div>
  )
}
