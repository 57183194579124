import React, { useState, useEffect, useRef } from "react"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import SubmitForm from "../SubmitForm"
import "./index.scss"
import PlacePost from "./PlacePost"
import ex from "./noun-x.svg"
import pencil from "./pencil-icon.svg"
import Modal from "./Modal"
import MapKey from "./MapKey"
import Sidebar from "./Sidebar"

export default function NewMap({ data }) {
  const [map, setMap] = useState(null)
  const mapContainerRef = useRef(null)
  const [place, setPlace] = useState({})
  const [hidden, setHidden] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [clickedPoint, setClickedPoint] = useState("")
  const [opened, setOpened] = useState(false)
  const [modal, setModal] = useState(false)
  const [sidebarShow, setSidebarShow] = useState(false)

  const sites = data.places.nodes

  //  = = = = = = = = = =
  // Create JSON Data for Map
  const mapPoints = sites?.map(site => {
    return {
      type: "Feature",
      properties: {
        name: site.name,
        id: site.id,
        typeOfPlace: site.typeOfPlace,
      },
      geometry: {
        type: "Point",
        coordinates: [site.location.longitude, site.location.latitude],
      },
    }
  })

  const pointCollection = {
    type: "FeatureCollection",
    features: mapPoints,
  }

  //    = = = = = = = = = =
  //   Event Handlers
  const clickPoint = clicked => {
    const filteredPost = sites.filter(site => site.name === clicked)
    setClickedPoint(filteredPost[0])
    setOpened(true)
  }
  const handlePostClose = () => {
    setOpened(false)
    setClickedPoint("")
    map.zoomOut({ offset: [80, 60] })
  }

  const hideInfo = () => {
    setHidden(true)
    localStorage.setItem("hiding", true)
  }

  useEffect(() => {
    const hiddenCheck = localStorage.getItem("hiding")
    hiddenCheck && setHidden(true)
  })

  //  = = = = = = = = = =
  // Map Init Styles
  const mapContainerStyle = {
    width: "100%",
  }
  // = = = = = = = = = = =
  // Map Section Data
  const MAPBOX_TOKEN =
    "pk.eyJ1Ijoib2theWtlbmRhbGwiLCJhIjoiY2w2NWF0N21yMDQ2NjNicWd2dDhmeHRkZSJ9.E0wSEWgbN_34_JflW6BfuQ"

  const geocoder = new MapboxGeocoder({
    accessToken: MAPBOX_TOKEN,
    zoom: 15,
    placeholder: "Type in a place, any place!",
    mapboxgl: mapboxgl,
    marker: { draggable: false },
  })

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/okaykendall/cl5k1py58002f14nc2exjmteb",
      center: [2.177432, 41.382894],
      zoom: 3.05,
      minZoom: 2,
    })

    map.addControl(geocoder, "top-left")

    const makeObject = result => {
      const contextObject = result?.features[0].context
      const filteredGuy = contextObject.filter(item =>
        item.id.includes("country")
      )
      setPlace({
        name: result?.features[0]?.place_name,
        latitude: result?.features[0]?.center[1],
        longitude: result?.features[0]?.center[0],
        country: filteredGuy[0].text,
      })
    }

    geocoder.on("results", function (result) {
      makeObject(result)
    })

    map.on("load", () => {
      map.addSource("points", {
        type: "geojson",
        data: pointCollection,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 60, // Radius of each cluster when clustering points (defaults to 50)
      })
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "points",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            // "#d96257",
            "#DB392A",
            10,
            "#f1f075",
            50,
            "#f28cb1",
          ],
          "circle-radius": ["step", ["get", "point_count"], 20, 10, 30, 50, 40],
        },
      })
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "points",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["PT Serif Bold", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": [
            "step",
            ["get", "point_count"],
            // "#d96257",
            "#f6f1e9",
            10,
            "#000000",
            50,
            "#f28cb1",
          ],
        },
      })
      map.addLayer({
        id: "points",
        type: "symbol",
        source: "points",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": [
            "match",
            ["get", "typeOfPlace"],
            "museum",
            "museum-v2",
            "restaurant",
            "restaurant-v2",
            "shop",
            "shop-v2",
            "market",
            "shop-v2",
            "bar_cafe",
            "beer-v2",
            "view",
            "viewpoint-v2",
            // "other",
            "star",
          ],
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            0.75,
            1.5,
            0.85,
            2,
            1,
            7.5,
            1.5,
            8,
            1.5,
            10,
            2,
          ],
        },
        paint: {
          "icon-color": "#2f6d4b",
          "icon-halo-color": "#f2c163",
          "icon-halo-width": 3,
          // "icon-halo-blur": 1,
        },
      })
      //Closure of onload function
    })
    // Helper Map Functions
    map.on("mouseenter", "points", () => {
      map.getCanvas().style.cursor = "pointer"
    })
    map.on("mouseleave", "points", () => {
      map.getCanvas().style.cursor = ""
    })
    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer"
    })
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = ""
    })

    // Click events here
    // inspect a cluster on click
    map.on("click", "clusters", e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      })
      const clusterId = features[0].properties.cluster_id
      map
        .getSource("points")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          })
        })
    })

    map.on("click", "points", e => {
      const clicked = e.features[0].properties.name
      clickPoint(clicked)
      console.log(e.target)
      map.flyTo({
        center: e.features[0].geometry.coordinates,
        zoom: 15,
        speed: 0.85,
      })
    })

    setMap(map)

    return () => map.remove()
  }, [])

  // Functions for the sidebar
  const flyToRegion = e => {
    const clickedLat = e.target.getAttribute("data-lat")
    const clickedLong = e.target.getAttribute("data-long")
    const clickedCoords = [clickedLong, clickedLat]
    map.flyTo({
      center: clickedCoords,
      zoom: 3,
    })
  }

  return (
    <div className="map-form-wrapper">
      <div className={`info-wrapper ${hidden && "hiding"}`}>
        <div className="close" onClick={() => hideInfo()}>
          <img src={ex} alt="close" />
        </div>
        <p>
          Some friends had a really great idea! Everytime we travel, we end up
          reaching out to people that we know have been to the place we're
          going. What if instead, there was one source of all this knowlegde,
          and you knew all the reccomendations were made my like-minded people
          (i.e., your friends!). This is the beginning of a project I hope to
          expand further, with filtering, authentication, and password
          protection for friend group maps that are most functional and
          endearing.
        </p>
        <p>
          Please - if you find yourself here - do me the great pleasure of
          leaving a reccomendation! If you leave your email, even if you choose
          not to publish it on the post, I'll be sure to send you a gif and a
          custom haiku. 🫂
        </p>
        <p>
          Everything you see here on the map was sourced from this very page!
          Using the Mapbox Geocoding API and the Dato CMS Content Delivery API.
        </p>
        <p>
          <strong>
            Click the pencil underneath this brief tale to leave your own lil
            special something ⤿
          </strong>
        </p>
      </div>
      <Modal setModal={setModal} modal={modal} />
      <MapKey />
      <div className="form-toggle" onClick={e => setShowForm(!showForm)}>
        <img src={pencil} />
      </div>
      <div className={`form-wrapper ${showForm && "show"}`}>
        <div className="close" onClick={e => setShowForm(!showForm)}>
          <img src={ex} alt="close" />
        </div>
        <SubmitForm place={place} setModal={setModal} />
      </div>
      <div className="map-wrapper">
        <Sidebar
          sidebarShow={sidebarShow}
          setSidebarShow={setSidebarShow}
          flyToRegion={flyToRegion}
        />
        <div ref={mapContainerRef} style={mapContainerStyle}></div>
        {clickedPoint ? (
          <PlacePost
            handlePostClose={handlePostClose}
            opened={opened}
            post={clickedPoint}
          />
        ) : null}
      </div>
    </div>
  )
}
