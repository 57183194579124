import React from "react"

export default function Modal({ setModal, modal }) {
  const handleModalClose = () => {
    setModal(false)
    window.location.reload(false)
  }
  return (
    <div className={`popup ${modal && "show"}`}>
      <div className="container">
        <div className="modal-wrapper">
          <h2>
            Yay! Your Post was submitted. When you close this window the page
            will be refreshed and you'll see it on the map :)
          </h2>
          <button onClick={() => handleModalClose()}>close ❌</button>
        </div>
      </div>
    </div>
  )
}
