import React, { useState } from "react"
import { buildClient } from "@datocms/cma-client-browser"

export default function SubmitForm({ place, setModal }) {
  const [name, setName] = useState(place?.name)
  const [description, setDescription] = useState("")
  const [type, setType] = useState("restaurant")
  const [who, setWho] = useState("")
  const [email, setEmail] = useState("")
  const [showEmail, setShowEmail] = useState(false)

  const createRecord = () => {
    const client = buildClient({
      apiToken: "eb6efdf2fd280d471903e04de31a4d",
    })
    const record = client.items.create({
      item_type: { type: "item_type", id: "1997519" },
      name: name,
      description: description,
      location: {
        latitude: place?.latitude,
        longitude: place?.longitude,
      },
      type_of_place: type,
      who: who,
      email: email,
      show_email: showEmail,
      country: place?.country,
    })
    setModal(true)
  }

  return (
    <div className="map-submit-form">
      <div className="container">
        <form>
          <div className="form-group row">
            <p className="intro">
              <span>⭐ </span>
              <em>
                First thing is first: find the place on the map using the search
                bar on the top left, then fill in the rest of the form
              </em>
            </p>
            <label htmlFor="name" className="col-md-12">
              What's it called?
              <input
                id="name"
                name="name"
                type="text"
                className="col-md-12"
                // placeholder={`${place ? place?.name : ""}`}
                onChange={e => setName(e.target.value)}
              ></input>
            </label>
          </div>
          <div className="form-group row">
            <label htmlFor="description" className="col-md-12">
              Tell me about it!
              <textarea
                name="description"
                id="description"
                className="col-md-12"
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </label>
          </div>
          <div className="form-group row">
            <label htmlFor="typeOfPlace" className="col-md-12">
              What type of place is it?
              <select
                name="type-of-place"
                id="type-of-place"
                className="col-md-12"
                onChange={e => setType(e.target.value)}
              >
                <option value="restaurant">Restaurant</option>
                <option value="bar_cafe">Bar/Cafe</option>
                <option value="view">Great View</option>
                <option value="museum">Museum</option>
                <option value="market">Market</option>
                <option value="shop">Shop</option>
                <option value="other">Other</option>
              </select>
            </label>
          </div>
          <div className="form-group row">
            <label htmlFor="who" className="col-md-6">
              What's your name?
              <input
                type="text"
                name="who"
                id="who"
                onChange={e => setWho(e.target.value)}
              />
            </label>
            <label htmlFor="email" className="col-md-6">
              Email (optional)
              <input
                type="text"
                name="email"
                id="email"
                onChange={e => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group row">
            <p>Show email in post?</p>
            <label htmlFor="who" className="switch">
              <input
                type="checkbox"
                name="show_email"
                id="show_email"
                onChange={e => setShowEmail(!showEmail)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </form>
        <div className="row">
          <div className="col-md-12 btn-col">
            <button
              disabled={
                (place?.name === undefined) | (place?.name === "")
                  ? true
                  : false
              }
              onClick={() => createRecord()}
            >
              post 📍
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
